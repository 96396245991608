import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Layout from '../components/Layout';
import EmployeeCard from '../sections/EmployeeCard';
import IntroWithCountersSection from '../sections/IntroWithCountersSection';
import TextImageSection from '../sections/TextImageSection';


export const AboutPageTemplate = ({
  aboutUs, author, quote, team, employee, quality
}) => (
  <div className="flex-grow-1">
    <div className="navHeight mt-3" />
    <Container>
      <Col className="px-lg-0 mb-5 mt-5" xs={ 12 } sm={ 12 } md={ 10 } lg={ 8 } xl={ 10 }>
        <blockquote className="blockquote" style={{ fontSize: '2.5rem' }}>
          <p className="mb-0 font-italic">{quote}</p>
          <footer className="blockquote-footer">{author}
          </footer>
        </blockquote>
      </Col>
    </Container>
    {aboutUs.map((about, i) => (
      <TextImageSection
        title={ about.title }
        description={ about.description }
        textDirection={ i % 2 === 0 ? 'right' : 'left' }
        image={ about.image }
        key={ i }
        textLg={ 5 }
        textXl={ 5 }
        imageStyle={{ height: '450px' }}
      />
    ))}
    <Container fluid className="bg-primary py-5">
      <Container>
        <Row noGutters>
          <Col className="line-decoration line-decoration-white pt-1 my-4 text-white align-self-center mr-3" xs={ 12 } sm={ 12 } md={ 12 } lg={ 4 } xl={ 4 }>
            <h3>{team.title}</h3>
            <p>{team.description}</p>
          </Col>
          <Col>
            <Row xs={ 1 } sm={ 1 } md={ 2 } lg={ 2 } xl={ 3 } className="mt-3">
              {employee.map((member, i) => (
                <Col className="d-flex justify-content-center text-center mt-4" key={ i }>
                  <EmployeeCard
                    title={ member.title }
                    name={ member.name }
                    telefon={ member.telefon }
                    email={ member.email }
                    image={ member.image }
                    key={ i }
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
    <IntroWithCountersSection title={ quality.title } description={ quality.description } certificates={ quality.certificates } />
  </div>
);

AboutPageTemplate.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  team: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  employee: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    telefon: PropTypes.string,
    email: PropTypes.string,
  })).isRequired,
  aboutUs: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.objectOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.text,
  })).isRequired,
  quality: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    certificates: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.objectOf(PropTypes.object),
      pdfFiles: PropTypes.arrayOf(PropTypes.object)
    }))
  }).isRequired,
};

const AboutPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;

  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.description }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <AboutPageTemplate
        aboutUs={ frontmatter.aboutUs }
        quote={ frontmatter.quote }
        author={ frontmatter.author }
        team={ frontmatter.team }
        employee={ frontmatter.employee }
        quality={ frontmatter.quality }
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export default AboutPage;

export const pageQuery = graphql`
    query AboutById($id: String!, $language: String!) {
        markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "about-page" }, language: { eq: $language } }) {
            fields {
                slug
            }
            frontmatter {
              language
              title
              description
              quote
              author
              aboutUs {
                title
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 1024, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              team {
                title
                description
              }
              employee {
                title
                name
                telefon
                email
                image {
                  childImageSharp {
                    fluid(maxWidth: 1024, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              quality {
                title
                description
                certificates {
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  pdfFiles {
                    source {
                      publicURL
                    }
                    downloadText
                  }
                }
              }
            }
        }
    }
`;
import PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PreviewCompatibleImage from '../components/PreviewCompatibleImage';


const EmployeeCard = ({
  title, name, telefon, email, image
}) => (

  <Container>
    <Row className="justify-content-center">
      <PreviewCompatibleImage
        imageInfo={{ image }}
        imageStyle={{
          borderRadius: '50%', width: '200px', height: '200px'
        }}
      />
    </Row>
    <Row className="justify-content-center text-white mt-3">
      <div>
        <span>{title}</span><br />
        <span>{name}</span><br />
        <a className="text-white" href={ `tel:${telefon}` }>{telefon}</a><br />
        <a className="text-white" href={ `mailto:${email}` }>{email}</a><br />
      </div>
    </Row>
  </Container>
);


EmployeeCard.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  telefon: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default EmployeeCard;
import { mdiFilePdf } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const TextImageSection = ({
  title, description, image, hasAction, actionRoute, actionText, textDirection, sectionClassName, pdfFiles, lineDecorationClassName, titleColorClassName, textLg, textXl, imageStyle
}) => (
  <Container className={ `${sectionClassName} py-5 my-0` } fluid>
    <Container>
      <Row className="justify-content-between" noGutters>
        <Col
          xs={ 12 }
          sm={ 12 }
          md={ 12 }
          lg={ textLg }
          xl={ textXl }
          className={
            `${textDirection === 'left'
              ? 'order-sm-0 order-md-0 order-lg-0 order-xl-0 order-xxl-0'
              : 'order-sm-0 order-md-0 order-lg-1 order-xl-1 order-xxl-1'
            } line-decoration ${ lineDecorationClassName } pt-1 my-4`
          }
        >
          <h3 className={ `text-break ${ titleColorClassName}` }>{title}</h3>
          <p>{description}</p>
          {hasAction && actionText !== ''
            && <Link to={ actionRoute } className="btn btn-primary">{actionText}</Link>}
          { pdfFiles && pdfFiles.filter((file) => file.source || file.downloadText).map((file, i) => (
            <a href={ file.source && file.source.publicURL ? file.source.publicURL : '' } target="_blank" rel="noreferrer" className="d-flex" key={ i }>
              <Icon path={ mdiFilePdf } size={ 1 } /><span>{ file.downloadText }</span>
            </a>
          ))}
        </Col>
        <Col
          xs={ 12 }
          sm={ 12 }
          md={ 12 }
          lg={ textLg < 11 ? 11 - textLg : 12 }
          xl={ textLg < 11 ? 11 - textLg : 12 }
          className={
            `${textDirection === 'left'
              ? 'order-sm-1 order-md-1 order-lg-1 order-xl-1 order-xxl-1'
              : 'order-sm-1 order-md-1 order-lg-0 order-xl-0 order-xxl-0'
            } my-4`
          }
        >
          <PreviewCompatibleImage imageInfo={{ image }} imageClasses="w-100" imageStyle={ imageStyle !== '' ? imageStyle : {} } />
        </Col>
      </Row>
    </Container>
  </Container>
);

TextImageSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.object),
  hasAction: PropTypes.bool,
  textDirection: PropTypes.string,
  actionRoute: PropTypes.string,
  actionText: PropTypes.string,
  sectionClassName: PropTypes.string,
  pdfFiles: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.object,
    downloadText: PropTypes.string
  })),
  lineDecorationClassName: PropTypes.string,
  titleColorClassName: PropTypes.string,
  textLg: PropTypes.number,
  textXl: PropTypes.number,
  imageStyle: PropTypes.objectOf(PropTypes.string)
};

TextImageSection.defaultProps = {
  image: undefined,
  hasAction: false,
  textDirection: 'left',
  actionRoute: '/',
  actionText: '',
  sectionClassName: '',
  pdfFiles: [],
  lineDecorationClassName: 'line-decoration-dark',
  titleColorClassName: '',
  textLg: 3,
  textXl: 3,
  imageStyle: undefined
};

export default TextImageSection;